











































.dropZone
  height: 180px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  .dropZoneCenter
    outline: 2px solid grey;
    outline-offset: -2px;
    border-radius: 5px;
    font-size: 0.8rem;
    height: 100%;
    padding-top: 25px;
    // added transition to make changes smooth
    -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;

  .dropZone-over
    outline-offset: -10px;

  .nonSelect
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

.center
  text-align: center;
  color: red;
  font-size: 1rem;

.itemList
  .items
    margin-top: 5px;

.splitpanes__pane
  justify-content: center;
  align-items: center;
  display: flex;

.splitpanes--vertical > .splitpanes__splitter
  min-width: 0.25rem;
  background: var(--v-scrollbarThumbColor-base);
  border-radius: 0.7rem;

.splitpanes--vertical > .splitpanes__splitter:hover
  min-width: 0.25rem;
  background: var(--v-scrollbarThumbHoverColor-base);
  border-radius: 0.7rem;
  -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
