

















































.border-field
  border: 2px solid var(--v-bgSecondary-base);
  margin-bottom: 5px;
  border-radius: 3px;

.border-checkbox
  margin: 3px solid var(--v-bgSecondary-base);
  padding: 10px;
